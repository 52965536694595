<template>
  <div v-if="state.detail">
    <div class="head-wrap">
      <div class="logo" v-if="state.detail.logo_stream">
        <img :src="logo" alt="">
      </div>
      <div class="title">{{ state.detail.title }}</div>
      <div class="year">会议年份：{{ state.detail.meeting_year }}</div>
      <div class="tool-wrap">
        <div class="text" @click="handlePreview">查看会议内容总览</div>
        <Icon class-prefix="iconfont" name="fenxiang1" color="#0C86FE" @click="handleCopyMeeting" />
      </div>
    </div>
    <div class="list-wrap">
      <div class="item-wrap" v-for="(item, index) in state.detail.topics" :key="item.id">
        <div class="top-wrap">
          <div class="title">题目{{ index + 1 }}</div>
          <Icon class-prefix="iconfont" name="fenxiang1" color="#0C86FE" @click="handleCopyTopic(item)" />
        </div>
        <div class="content" @click="handlePrviewItem(item)">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Icon, Toast } from 'vant'
import { getMeetingDetail } from '@/api/'

const route = useRoute()
const id = route.query.id
const state = reactive({
  detail: null
})
const base64Map = new Map([
  ['png', 'data:image/png;base64,'],
  ['jpg', 'data:image/jpg;base64,'],
  ['jpeg', 'data:image/jpeg;base64,'],
  ['gif', 'data:image/gif;base64,'],
  ['bmp', 'data:image/bmp;base64,']
])

const logo = computed(() => {
  if (state.detail && state.detail.logo_stream) {
    return `${base64Map.get(state.detail.logo.split('.').pop())}${state.detail.logo_stream}`
  }
  return ''
})

getMeetingDetail(id, { t: new Date().getTime() }).then(res => {
  console.log(res)
  state.detail = res.data
})

const copyText = (text) => {
  const input = document.createElement('input')
  input.setAttribute('readonly', 'readonly')
  input.setAttribute('value', text)
  document.body.appendChild(input)
  input.select()
  input.setSelectionRange(0, 9999)
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    Toast.success('复制成功')
  }
  document.body.removeChild(input)
}

const handleCopyMeeting = () => {
  copyText(state.detail.file_preview)
}
const handleCopyTopic = (item) => {
  copyText(item.file_preview)
}

const handlePreview = () => {
  if (!state.detail.file_preview) return
  window.location.href = state.detail.file_preview
}

const handlePrviewItem = (item) => {
  window.location.href = item.file_preview
}
</script>

<style lang="less" scoped>
.head-wrap {
  padding: 16px;
  background-color: #fff;

  .logo {
    width: 100%;
    height: 62px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .title {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
  }

  .year {
    margin-top: 12px;
    font-size: 14px;
    color: #999999;
  }

  .tool-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .text {
      font-size: 14px;
      color: #0C86FE;
    }
  }
}

.list-wrap {
  margin-top: 10px;
  background-color: #fff;

  .item-wrap {
    padding: 16px;

    .top-wrap {
      display: flex;
      justify-content: space-between;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: #0C86FE;
        border-radius: 2px;
      }

      .title {
        font-size: 14px;
        color: #333333;
        padding-left: 10px;
      }
    }

    .content {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #0C86FE;
    }
  }
}
</style>
